import React from 'react';
import SEO from '../components/Seo';
import { graphql, Link } from 'gatsby';
import DesktopLayout from '../layouts/Desktop';

const BlogPost = ({ data }) => {
  const post = data.wpPost;
  return (
    <DesktopLayout>
      <SEO title={post.title} description={post.excerpt} />
      <div>
        <h1>{post.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <Link to="/">Go To HomePage</Link>
      </div>
    </DesktopLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug } ) {
      excerpt
      date
      content
      title
      uri
    }
  }
`;

export default BlogPost;
